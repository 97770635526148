export enum Profile {
    DEVELOPMENT = 'development',
    LOCAL = 'local',
    STAGING = 'staging',
    PRODUCTION = 'production',
}

interface EnvironmentConstructor {
    profile: Profile;
    apiUrl: string;
    mainUrl: string;
    wordPressUrl: string;
    googleAnalyticsId?: string;
    googleApiKey?: string;
    sentryDsn?: string;
}

class Environment {
    profile: Profile;

    apiUrl: string;

    mainUrl: string;

    wordPressUrl: string;

    googleApiKey: string;

    googleAnalyticsId: string;

    sentryDsn: string;

    constructor({ profile, apiUrl, mainUrl, wordPressUrl, googleApiKey, googleAnalyticsId }: EnvironmentConstructor) {
        this.profile = profile;
        this.apiUrl = apiUrl;
        this.mainUrl = mainUrl;
        this.wordPressUrl = wordPressUrl;
        this.googleApiKey = googleApiKey ?? 'AIzaSyCFslwPYsWPOtWFLUyVgHqNh0CjeoDAN4k';
        this.googleAnalyticsId = googleAnalyticsId ?? 'G-VDB5HCWX6C';
        this.sentryDsn = 'https://a74ff54d5b25fa1854fdf87a7558e5ba@o4506518710386688.ingest.sentry.io/4506529932574720';
    }
}

const ENV_VARS: { [A in Profile]: Environment } = {
    [Profile.DEVELOPMENT]: new Environment({
        profile: Profile.DEVELOPMENT,
        apiUrl: 'https://localhost:8443/',
        mainUrl: 'https://localhost:3000/',
        wordPressUrl: 'https://www.busfinder.com/',
    }),
    [Profile.LOCAL]: new Environment({
        profile: Profile.LOCAL,
        apiUrl: 'https://localhost:8443/',
        mainUrl: 'https://localhost:3000/',
        wordPressUrl: 'https://www.busfinder.com/',
    }),
    [Profile.STAGING]: new Environment({
        profile: Profile.STAGING,
        apiUrl: 'https://app.bus.busworld24.com/tomcat/',
        mainUrl: 'https://www.bus.busworld24.com/',
        wordPressUrl: 'https://www.busfinder.com/',
    }),
    [Profile.PRODUCTION]: new Environment({
        profile: Profile.PRODUCTION,
        apiUrl: 'https://app.busfinder.com/tomcat/',
        mainUrl: 'https://booking.busfinder.com/',
        wordPressUrl: 'https://www.busfinder.com/',
        googleApiKey: 'AIzaSyBwME26yqavFsZZnM7vcUEmPFN-l-_FmFQ',
        googleAnalyticsId: 'G-7ZT1D806ZY',
    }),
};

// Load environment variables for the active profile
const currentProfile = (import.meta.env.VITE_PROFILE as Profile) || Profile.DEVELOPMENT;
export const ENV = ENV_VARS[currentProfile];

export const IS_DEVELOPMENT = ENV.profile === Profile.DEVELOPMENT;
export const IS_LOCAL = ENV.profile === Profile.LOCAL;
export const IS_STAGING = ENV.profile === Profile.STAGING;
export const IS_PRODUCTION = ENV.profile === Profile.PRODUCTION;

console.info(`Environment ${ENV.profile}`);
console.info(ENV);
