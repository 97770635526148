import tinycolor from 'tinycolor2';
import { mixed, string } from 'yup';

const CSS_UNIT = /^\s*(\d+(?:\.\d+)?|\.\d+)\s*(px|em|rem|%|in|cm|mm|ex|pt|pc)\s*$/i;
const NUMBER_STR = /^\s*\d+\s*$/;

export const S_CSS_UNIT = mixed<string | number>({ type: 'css-unit' }).transform((value: string | number) => {
    switch (typeof value) {
        case 'string': {
            if (CSS_UNIT.test(value)) return value;
            if (NUMBER_STR.test(value)) return Number.parseFloat(value);
            return undefined;
        }
        case 'number': {
            return value;
        }
        default: {
            return undefined;
        }
    }
});

export const S_REM_UNIT = mixed<string | number>({ type: 'number' }).transform(value => {
    if (value === '') return undefined;

    const stripped = String(value).replace('rem', '');
    return `${stripped}rem`;
});

export const S_CSS_NUMBER = mixed<number>({ type: 'number' }).transform(value => {
    if (value === '') return undefined;
    return typeof value === 'string' ? Number.parseFloat(value) : value;
});

export const S_CSS_COLOR = string()
    .meta({ type: 'color' })
    .transform((value: string) => {
        const color = tinycolor(value);
        return color.isValid() ? color.toRgbString() : undefined;
    });

export const S_TYPOGRAPHY_STRING = string().transform((value: string) => (value === '' ? undefined : value));
