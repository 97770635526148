import React from 'react';

import { type AppSettings } from '@/core/app-ctx/AppSettings';
import { type LocalizationOverrides } from '@/core/localization/mod';
import { type ThemeOverrides } from '@/core/theme/ThemeOverrides';
import { type EmbedType } from '@/embed/Embed';

export interface IEmbedCtxContext {
    isEmbedding: boolean;
    embedType?: EmbedType;
    portalContainer?: HTMLElement;
    shadowRoot?: ShadowRoot | null;
    breakpointScalingFactor?: number;
    integrationKey?: string;
    injectedThemeOverrides?: ThemeOverrides | null;
    injectedLocalizationOverrides?: LocalizationOverrides | null;
    injectedAppSettings?: AppSettings | null;
    disableTilePartScrolling?: boolean | null;
}

export const EmbedCtxContext = React.createContext<IEmbedCtxContext>({
    isEmbedding: false,
});

export function useEmbedCtx(): IEmbedCtxContext {
    const context = React.useContext(EmbedCtxContext);

    // if (!context) throw new Error('useIntegrationCtx must be used within a IntegrationCtxProvider');

    return context;
}
